import Error from "../../components/404";

function ErrorPage() {
  return (
    <>
      <Error />
    </>
  );
}

export default ErrorPage;
