import Auth from "../../components/auth/index.js";

function UserAuth() {
  return (
    <>
      <Auth />
    </>
  );
}

export default UserAuth;
